<script setup>
const {color} = defineProps({
  color: {
    type: String,
  },
})
</script>

<template>
  <g>
    <path
        d="M12.0002 7.2002L12.0002 16.8002M16.8002 12.0002L7.2002 12.0002"
        :stroke="color"
        stroke-width="2"
        stroke-linecap="round"
    >
    </path>
  </g>
</template>