<template>
    <button class="btn-secondary" :disabled="disabled" @click="handleClick">
        <slot>Button</slot>
    </button>
</template>

<script setup>
    import { defineProps, defineEmits } from 'vue';

    const props = defineProps({
    disabled: {
        type: Boolean,
        default: false
    }
    });

    const emits = defineEmits(['click']);

    function handleClick(event) {
        if (props.disabled) {
            event.preventDefault();
            return;
        }
        emits('click', event);
    }
</script>

<style scoped>
.btn-secondary {
    padding: 5px 10px;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    color: black;
    background: #FFFFFF;
    border-radius: 8px;
    transition-duration: .5s;
}

.btn-secondary:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #FFFFFF;
}

.btn-secondary:active {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #FFFFFF;
}

.btn-secondary:disabled {
    color: rgba(255, 255, 255, 0.4);
    background: rgba(255, 255, 255, 0.1);
    cursor: not-allowed;
}
</style>