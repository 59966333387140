<template>
  <div class="hover-container" @mouseenter="showPopover" @mouseleave="hidePopover" ref="hoverContainer">
    <span class="hover-text">
      <slot></slot>
    </span>
    <div v-if="visible" class="popover-wrapper" :style="popoverStyle">
      <div class="popover">
        <div class="arrow" :style="arrowStyle"></div>
        <div class="message" v-html="message"></div>
      </div>
    </div>

  </div>
</template>

<script setup>
import { ref, reactive, nextTick } from 'vue';

const {message} = defineProps({
  message: {
    type: String,
    required: true
  }
});

const visible = ref(false);
const popoverStyle = reactive({
  top: '0px',
  left: '0px'
});
const arrowStyle = reactive({
  left: '50%'
});
const hoverContainer = ref(null);

async function showPopover() {
  visible.value = true;
  await nextTick();

  const popover = hoverContainer.value.querySelector('.popover');
  if (!popover) {
    console.error('Popover element not found in DOM');
    return;
  }

  const containerRect = hoverContainer.value.getBoundingClientRect();
  const popoverWidth = 250;
  const arrowHeight = 10;

  let left = containerRect.left + containerRect.width / 2 - popoverWidth / 2;
  const popoverHeight = popover.offsetHeight;
  let top = containerRect.top - popoverHeight - arrowHeight;

  left = Math.max(left, 10);

  const rightOverflow = left + popoverWidth - window.innerWidth;
  if (rightOverflow > 0) {
    left -= rightOverflow + 10;
  }

  // Adjust for the offset of the fixed container
  const fixedContainer = hoverContainer.value.closest('.fixed-container');
  if (fixedContainer) {
    const fixedRect = fixedContainer.getBoundingClientRect();
    top -= fixedRect.top;
    left -= fixedRect.left;
  }

  popoverStyle.top = `${top}px`;
  popoverStyle.left = `${left}px`;

  const arrowLeft = (containerRect.left + containerRect.width / 2) - left;
  arrowStyle.left = `${arrowLeft}px`;
}

function hidePopover() {
  visible.value = false;
}
</script>


<style scoped>
.hover-container {
  position: relative;
  display: inline-block;
}

.hover-text {
  cursor: pointer;
  color: #FFF;
}

.popover-wrapper {
  position: fixed;
  padding-bottom: 1rem;
}

.popover {
  position: relative;
  width: 250px;
  padding: 15px;
  background-color: rgba(44, 43, 44, 1);
  color: white;
  border-radius: 6px;
  text-align: left;
  box-shadow: 0px 8px 30px rgba(0,0,0,0.5); 
  z-index: 1001;
  white-space: normal;
}

.arrow {
  position: absolute;
  bottom: -10px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid rgba(44, 43, 44, 1);
}

.message {
  word-wrap: break-word;
}
</style>
