<script setup>
const {color} = defineProps({
  color: {
    type: String,
  },
})
</script>

<template>
  <g>
    <path
        d="M10.0815 11.5189L3.56152 4.99892C3.13303 4.57044 2.40039 4.87391 2.40039 5.47987L2.40039 18.5198C2.40039 19.1258 3.13303 19.4293 3.56152 19.0008L10.0815 12.4808C10.3471 12.2152 10.3471 11.7845 10.0815 11.5189Z"
        :stroke="color"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
    </path>
    <path
        d="M21.4012 11.5189L14.8812 4.99892C14.4527 4.57044 13.7201 4.87391 13.7201 5.47987V18.5198C13.7201 19.1258 14.4527 19.4293 14.8812 19.0008L21.4012 12.4808C21.6668 12.2152 21.6668 11.7845 21.4012 11.5189Z"
        :stroke="color"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
    </path>
  </g>
</template>