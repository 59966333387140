<script setup>
const {color} = defineProps({
  color: {
    type: String,
  },
})
</script>

<template>
  <g>
    <path
        d="M10.5091 3.83008L8.45864 9.1427L12.0004 20.1699M12.0004 20.1699L15.6353 9.0495L13.6781 3.83008M12.0004 20.1699L2.40039 9.2359M12.0004 20.1699L21.6004 9.2359M2.40039 9.2359L6.50136 3.83008H17.5926L21.6004 9.2359M2.40039 9.2359H21.6004"
        :stroke="color"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
    </path>
  </g>
</template>