<script setup>
const {color} = defineProps({
  color: {
    type: String,
  },
})
</script>

<template>
  <g>
    <path
        d="M17.9733 10.9373C19.3397 11.6988 19.3447 12.6568 17.9733 13.5177L7.37627 20.6645C6.04478 21.3751 5.14046 20.9556 5.04553 19.418L5.00057 4.45982C4.97059 3.04355 6.13721 2.6434 7.24887 3.32244L17.9733 10.9373Z"
        :stroke="color"
        stroke-width="2"
    >
    </path>
  </g>
</template>