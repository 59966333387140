<template>
  <div class="page-wrapper" ref="pageWrapper">
    <header class="header" ref="header">
      <div class="hamburger-menu control-btn" @click="toggleLeftPanel">
        <BaseIcon iconName="hamburger"></BaseIcon>
      </div>

      <div class="header-content">
        <img src="@/assets/logo.png" alt="Logo" class="logo" />
        <h1 class="header-1">AvaCapo</h1>
      </div>

      <div class="control-btn" @click="handleHomeButton">
        <BaseIcon iconName="bell"></BaseIcon>
      </div>
    </header>

    <div class="main-content">
      <div class="left-panel" :class="{ open: isLeftPanelOpen }">
        <div class="nav-bar">
          <MenuCell iconName="home" to="/app/home">
            Home
          </MenuCell>

          <MenuCell iconName="folder" to="/app/animations">
            Animations
          </MenuCell>

          <MenuCell iconName="star" :locked="false" to="/app/favorites">
            Favorites
          </MenuCell>

          <MenuCell iconName="book" :locked="true" to="/app/tutorials">
            Tutorials
          </MenuCell>
        </div>

        <div class="bottom-panel">
          {{ email }}
          <BaseIcon
            iconName="dotHorizontal"
            @click="toggleContextMenu"
            class="bottom-panel-icon"
          >
          </BaseIcon>
        </div>

        <ContextMenu ref="contextMenu" @item-click="handleMenuItemClick" />
      </div>

      <div class="content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, nextTick } from 'vue';

import BaseIcon from '@/components/BaseIcon.vue';
import MenuCell from '@/components/MenuCell.vue';
import ContextMenu from '@/components/ContextMenu.vue';

import { useStore } from "vuex";

const store = useStore();
const email = store.getters.getEmail;
console.log(email);

const contextMenu = ref(null);
const header = ref(null);
const pageWrapper = ref(null);
const headerHeight = ref(0);
const paddingTop = ref(0);
const isLeftPanelOpen = ref(false);

function toggleLeftPanel() {
  isLeftPanelOpen.value = !isLeftPanelOpen.value;
}

function toggleContextMenu(event) {
  if (contextMenu.value) {
    contextMenu.value.showMenu(event);
  }
}

function handleMenuItemClick(item) {
  console.log('Menu item clicked:', item);
}

onMounted(async () => {
  await nextTick();
  updateDimensions();
  window.addEventListener('resize', updateDimensions);
});

function updateDimensions() {
  headerHeight.value = header.value.offsetHeight;
  paddingTop.value = parseFloat(getComputedStyle(pageWrapper.value).paddingTop);
}

onUnmounted(() => {
  window.removeEventListener('resize', updateDimensions);
});
</script>

<style scoped>
.page-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  box-sizing: border-box;
  padding: 1vh 2vw;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.header-content {
  display: flex;
  align-items: center;
}

.logo {
  max-height: 100%;
}

.hamburger-menu {
  display: none;
  cursor: pointer;
}

.main-content {
  display: flex;
  height: calc(100vh - 10vh);
  overflow: hidden;
}

.left-panel {
  height: 87%;
  position: fixed;
  width: calc(20% - 2vw);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding-bottom: 2vh;
}

.left-panel.open {
  transform: translateX(0);
  padding-right: 2rem;
}

.nav-bar {
  display: flex;
  flex-direction: column;
}

.menu-item {
  display: flex;
  align-items: center;
  padding: 15px 6px;
  text-decoration: none;
  border-radius: 5px;
  color: rgba(255, 255, 255, 0.4);
}

.reset-decoration {
  text-decoration: none;
}

.icon-padding {
  padding-right: 10px;
}

.router-link-active {
  color: rgba(255, 255, 255, 1);
  background-color: rgba(255, 255, 255, 0.1);
}

.content {
  margin-left: 20%;
  padding: 0 2vw;
  width: calc(80% - 2vw);
  overflow-y: auto;
  height: calc(100vh - var(--header-height));
}

.bottom-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2vh;
}

.bottom-panel-icon {
  cursor: pointer;
}

@media (max-width: 768px) {
  .left-panel {
    width: 80%;
    transform: translateX(-120%);
    transition: transform 0.3s ease;
  }

  .hamburger-menu {
    display: flex;
  }

  .content {
    margin-left: 0;
    width: 100%;
    transition: filter 0.3s ease;
  }

  .left-panel.open + .content {
    margin-left: 80%;
    overflow: hidden;
    filter: blur(3px);
  }
}

@media (max-height: 600px) {
  .left-panel {
    height: 80vh;
  }
}
</style>
