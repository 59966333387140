<script setup>
const {color} = defineProps({
  color: {
    type: String,
  },
})
</script>

<template>
  <g>
    <path
        d="M17.4004 9.8222L21.6004 11.975L12.0004 16.8958L2.40039 11.975L6.67716 9.78286M17.4004 14.5263L21.6004 16.6792L12.0004 21.5999L2.40039 16.6792L6.67716 14.487M12.0004 2.3999L21.6004 7.32065L12.0004 12.2414L2.40039 7.32065L12.0004 2.3999Z"
        :stroke="color"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
    </path>
  </g>
</template>