<script setup>
const {color} = defineProps({
  color: {
    type: String,
  },
})
</script>

<template>
  <g>
    <path
        d="M20.4004 19.5L5.40039 4.5M10.2004 10.4416C9.82697 10.8533 9.60039 11.394 9.60039 11.9863C9.60039 13.2761 10.6749 14.3217 12.0004 14.3217C12.6115 14.3217 13.1693 14.0994 13.593 13.7334M20.4392 14.3217C21.2654 13.0848 21.6004 12.0761 21.6004 12.0761C21.6004 12.0761 19.4158 5.1 12.0004 5.1C11.5841 5.1 11.1843 5.12199 10.8004 5.16349M17.4004 17.3494C16.023 18.2281 14.2497 18.8495 12.0004 18.8127C4.67731 18.693 2.40039 12.0761 2.40039 12.0761C2.40039 12.0761 3.45825 8.69808 6.60039 6.64332"
        :stroke="color"
        stroke-width="2"
        stroke-linecap="round"
    >
    </path>
  </g>
</template>