<template>
  <div class="section">
    <div class="center-section">
      <div class="form-heading">
        <div class="header-1">
          <span v-if="isConfirmed">Thank you for confirming your registration!</span>
          <span v-else-if="isError">There was an error confirming your registration. Please try again.</span>
          <span v-else>Confirming your registration...</span>
        </div>
      </div>

      <div v-if="isConfirmed" class="login-link caption">
        <router-link to="/login" class="link">Log in</router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

const isConfirmed = ref(false);
const isError = ref(false);
const route = useRoute();

onMounted(async () => {
  const token = route.params.token;
  if (token) {
    try {
      const response = await fetch(`/confirm-email/${token}/`, {
        method: 'GET'
      });
      if (response.status === 202) {
        isConfirmed.value = true;
      } else {
        isError.value = true;
      }
    } catch (error) {
      isError.value = true;
    }
  } else {
    isError.value = true;
  }
});
</script>

<style scoped>
.section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.center-section {
  width: 35%;
  margin: auto;
}

.form-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 1vh;
}

.form-heading .caption {
  color: rgba(255, 255, 255, .4);
  text-align: center;
}

.form-heading img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

.signup-form {
  padding: 2.5vh;
  margin-bottom: 24px;
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-radius: 20px;
}

.reset {
  margin-top: 1vh;
  display: flex;
  justify-content: center;
}

.form-group {
  padding: 1vh;
}

.login-link {
  text-align: center;
  color: rgba(255, 255, 255, .4);
}

.link {
  color: white;
  text-decoration: none;
}
</style>
