<script setup>
const {color} = defineProps({
  color: {
    type: String,
  },
})
</script>

<template>
  <g>
    <path
        d="M4.87115 14.5C5.88914 17.6939 8.80463 20 12.2424 20C16.5268 20 20 16.4183 20 12C20 7.58172 16.5268 4 12.2424 4C9.37103 4 6.86399 5.60879 5.52267 8M7.87879 9H4V5"
        :stroke="color"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
    </path>
  </g>
</template>