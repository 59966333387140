<script setup>
const {color} = defineProps({
  color: {
    type: String,
  },
})
</script>

<template>
  <g>
    <path
        d="M7.20039 12.0001C7.20039 13.3256 6.12587 14.4001 4.80039 14.4001C3.47491 14.4001 2.40039 13.3256 2.40039 12.0001C2.40039 10.6746 3.47491 9.6001 4.80039 9.6001C6.12587 9.6001 7.20039 10.6746 7.20039 12.0001Z"
        :fill="color"
    >
    </path>
    <path
        d="M14.4004 12.0001C14.4004 13.3256 13.3259 14.4001 12.0004 14.4001C10.6749 14.4001 9.60039 13.3256 9.60039 12.0001C9.60039 10.6746 10.6749 9.6001 12.0004 9.6001C13.3259 9.6001 14.4004 10.6746 14.4004 12.0001Z"
        :fill="color"
    >
    </path>
    <path
        d="M21.6004 12.0001C21.6004 13.3256 20.5259 14.4001 19.2004 14.4001C17.8749 14.4001 16.8004 13.3256 16.8004 12.0001C16.8004 10.6746 17.8749 9.6001 19.2004 9.6001C20.5259 9.6001 21.6004 10.6746 21.6004 12.0001Z"
        :fill="color"
    >
    </path>
  </g>
</template>