<template>
    <div class="section">
        <div class="center-section">
            <div class="form-heading">
                <p class="header-1">
                    Successful password change!
                </p>

                <p class="caption">
                    You can now use your new password to Log In to your account
                </p>
            </div>

            <div class="login-link caption">
                <router-link    to="/login" 
                                class="link">
                                Back to Log in
                </router-link>
            </div>
        </div>
    </div>

</template>

<script setup>
</script>

<style scoped>
    .signup-btn-group {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 1vh;
    }

    .center-section {
        width: 35%;
        margin: auto;
    }

    .form-heading {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-bottom: 1vh;
    }

    .form-heading .caption {
        color: rgba(255, 255, 255, .4);
        text-align: center;
    }

    .form-heading img {
        max-width: 100%;
        height: auto;
        object-fit: contain;
    }

    .signup-form {
        padding: 2.5vh;
        margin-bottom: 24px;
        background-color: rgba(255, 255, 255, 0.1) !important;
        border-radius: 20px;
    }

    .reset {
        margin-top: 1vh;
        display: flex;
        justify-content: center;
    }

    .form-group {
        padding: 1vh;
    }

    .login-link {
        text-align: center;
        color: rgba(255, 255, 255, .4);
    }

    .link {
        color: white;
        text-decoration: none;
    }

    .header-1 {
        text-align: center;
    }
</style>