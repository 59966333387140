<template>
    <div class="page-wrapper">
        <header class="header">
            <div class="control-btn" @click="handleBackBtn">
                <BaseIcon iconName="chevronLeft"></BaseIcon>
            </div>

            <div class="header-content">
                <img src="@/assets/logo.png" alt="Logo" class="logo" />
                <h1 class="header-1">AvaCapo</h1>
            </div>
        </header>

        <div class="page-content">
            <router-view></router-view>
        </div>
       
    </div>
</template>

<script setup>
    import BaseIcon from '@/components/BaseIcon.vue';
    import { useRouter } from 'vue-router';
    const router = useRouter();

    function handleBackBtn() {
        router.push('app');
    }
</script>

<style scoped>
    .page-wrapper {
        height: 100vh;
        flex-direction: column;
        justify-content: space-between;
        padding: 1vh 2vw;
    }

    .header-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        padding-right: 46px;
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 10vh;
    }

    .control-btn {
        flex-shrink: 0;
    }

    .logo {
        height: 50px;
    }

    .page-content {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80vh;
    }
</style>