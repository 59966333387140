<script setup>
const {color} = defineProps({
  color: {
    type: String,
  },
})
</script>

<template>
  <g>
    <path
        d="M8.82353 4H5.11765C4.55601 4 4.01738 4.22125 3.62024 4.61508C3.22311 5.0089 3 5.54305 3 6.1V18.7C3 19.257 3.22311 19.7911 3.62024 20.1849C4.01738 20.5787 4.55601 20.8 5.11765 20.8H8.82353M9.08672 12.4H21.0867M21.0867 12.4L16.5016 7.6M21.0867 12.4L16.5016 17.2"
        :stroke="color"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
    </path>
  </g>
</template>