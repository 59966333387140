<template>
    <div class="viewer-wrapper">
        <header class="anim-view-header">
            <div class="home-btn control-btn" @click="handleHomeButton">
                <BaseIcon iconName="home"></BaseIcon>
            </div>

            <div class="logo">
                <img src="@/assets/logo.png" alt="Logo" class="logo" />
                <h1 class="header-1">AvaCapo</h1>
            </div>

            <div class="controls">
                <div class="control-btn locked" @click="handleSettings">
                    <BaseIcon   iconName="settings"
                                color="rgba(255,255,255,0.4)">
                    </BaseIcon>
                </div>
                <div class="control-btn" @click="handleDownload">
                    <BaseIcon iconName="download"></BaseIcon>
                </div>
            </div>
        </header>

        <div class="app-three-model-viewer">
            <ThreeModelViewer ref="threeModelViewer" :animationId="animationId"/>
        </div>
    </div>

</template>

<script setup>
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import ThreeModelViewer from '../components/ThreeModelViewer.vue';
import BaseIcon from '@/components/BaseIcon.vue';

const route = useRoute();
const router = useRouter();

const animationId = route.query.animationId;

const threeModelViewer = ref(null);

function handleHomeButton() {
    router.push('/app/home');
}

function handleDownload() {
  if (threeModelViewer.value && threeModelViewer.value.downloadModel) {
    threeModelViewer.value.downloadModel();
  } else {
    console.error('ThreeModelViewer component not found or downloadModel method not exposed');
  }
}
</script>

<style scoped>
.viewer-wrapper {
    padding: 1%;
}

.anim-view-header {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center
}

.controls {
    display: flex;
    align-items: center;
    justify-content: center
}

.locked {
  cursor: not-allowed;
}
</style>