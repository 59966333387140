<template>
    <div class="page-wrapper">
        <header class="header">
            <div class="header-content">
                <img src="@/assets/logo.png" alt="Logo" class="logo" />
                <h1 class="header-1">AvaCapo</h1>
            </div>
        </header>

        <router-view></router-view>

        <footer>
            <p class="caption">Term of use | Privacy policy</p>
        </footer>
    </div>
</template>

<script setup>
</script>

<style scoped>
    .page-wrapper {
        height: 98vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 1vh;
    }

    .header-content {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .logo {
        height: 50px;
    }

    footer {
        text-align: center;
    }

    .caption {
        color: rgba(255, 255, 255, .4);
    }
</style>