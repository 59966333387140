<script setup>
const { color } = defineProps({
  color: {
    type: String,
    default: '#000000',
  },
});
</script>

<template>
  <g fill="none">
    <path
      d="M12 2.5l3.09 6.26L22 9.27l-5 4.87L18.18 21 12 17.77 5.82 21 7 14.14 2 9.27l6.91-1.01L12 2.5z"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </g>
</template>

<style scoped>
  g {
    display: inline-block;
    vertical-align: middle;
  }
</style>
