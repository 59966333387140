<template>
  <path
      d="M22 6C22 8.20914 20.2091 10 18 10C15.7909 10 14 8.20914 14 6C14 3.79086 15.7909 2 18 2C20.2091 2 22 3.79086 22 6Z"
      fill="url(#notification_dot_gradient)"/>
  <defs>
    <linearGradient id="notification_dot_gradient" x1="19.0436" y1="-42.72" x2="32.6026" y2="-40.4255"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0.257383" stop-color="#20AD8B"/>
      <stop offset="0.759752" stop-color="#ACE41B"/>
    </linearGradient>
  </defs>
</template>