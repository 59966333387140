<template>
    <div>
     404 error
    </div>
</template>

<script setup>

</script>

<style scoped>

</style>