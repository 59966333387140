<script setup>
const {color} = defineProps({
  color: {
    type: String,
  },
})
</script>

<template>
  <g fill="none">
    <path
        d="M6.5998 8.7999V7.88562C6.5998 4.84671 9.00838 2.3999 11.9998 2.3999C14.9912 2.3999 17.3998 4.84671 17.3998 7.88562V8.7999M6.5998 8.7999C5.6098 8.7999 4.7998 9.62276 4.7998 10.6285V19.7713C4.7998 20.777 5.6098 21.5999 6.5998 21.5999H17.3998C18.3898 21.5999 19.1998 20.777 19.1998 19.7713V10.6285C19.1998 9.62276 18.3898 8.7999 17.3998 8.7999M6.5998 8.7999H17.3998"
        :stroke="color"
        stroke-width="2"
        stroke-linecap="round"
    >
    </path>
  </g>
</template>