<script setup>
const {color} = defineProps({
  color: {
    type: String,
  },
})
</script>

<template>
  <g fill="none">
    <path
        d="M8.80039 2.3999H4.53372C3.35552 2.3999 2.40039 3.35503 2.40039 4.53324V8.7999M8.80039 21.5999H4.53372C3.35552 21.5999 2.40039 20.6448 2.40039 19.4666V15.1999M15.2004 2.3999H19.4671C20.6453 2.3999 21.6004 3.35503 21.6004 4.53324V8.7999M21.6004 15.1999V19.4666C21.6004 20.6448 20.6453 21.5999 19.4671 21.5999H15.2004M15.6004 12.0002C15.6004 13.9884 13.9886 15.6002 12.0004 15.6002C10.0122 15.6002 8.40039 13.9884 8.40039 12.0002C8.40039 10.012 10.0122 8.40019 12.0004 8.40019C13.9886 8.40019 15.6004 10.012 15.6004 12.0002Z"
        :stroke="color"
        stroke-width="2"
        stroke-linecap="round"
    >
    </path>
  </g>
</template>