<script setup>
const {color} = defineProps({
  color: {
    type: String,
  },
})
</script>

<template>
  <g>
    <path
        d="M12.0005 20L18.9287 16V8L12.0005 4L5.07227 8V16L12.0005 20ZM12.0005 20V12.5M12.0005 12.5L5.50047 8.5M12.0005 12.5L18.5005 8.5"
        :stroke="color"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
    </path>
  </g>
</template>