<script setup>
const {color} = defineProps({
  color: {
    type: String,
  },
})
</script>

<template>
  <g>
    <path
        d="M11.7261 20.2286V5.4172M11.7261 20.2286L10.2686 18.7712C9.44561 17.9481 8.32986 17.4858 7.16594 17.4858H3.49658C2.89064 17.4858 2.40039 16.9946 2.40039 16.3886V4.86863C2.40039 4.26269 2.8916 3.77148 3.49753 3.77148H7.71401C8.87793 3.77148 9.99418 4.23385 10.8172 5.05687L11.7261 5.96577L12.635 5.05687C13.458 4.23385 14.5743 3.77148 15.7382 3.77148H20.5032C21.1092 3.77148 21.6004 4.26269 21.6004 4.86863V16.3886C21.6004 16.9946 21.1092 17.4858 20.5032 17.4858H16.2868C15.1228 17.4858 14.0066 17.9481 13.1836 18.7712L11.7261 20.2286Z"
        :stroke="color"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
    </path>
  </g>
</template>