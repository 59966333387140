<script setup>
const {color} = defineProps({
  color: {
    type: String,
  },
})
</script>

<template>
  <g>
    <path
        d="M19.5793 10.4841V7.27528C19.5793 6.81505 19.2062 6.44195 18.746 6.44195H11.8403C11.6193 6.44195 11.4073 6.35415 11.251 6.19787L9.71815 4.66498C9.56187 4.5087 9.34991 4.4209 9.1289 4.4209H3.23372C2.77349 4.4209 2.40039 4.79399 2.40039 5.25423V18.7455C2.40039 19.2057 2.77349 19.5788 3.23372 19.5788H4.59864C5.05887 19.5788 5.43197 19.2057 5.43197 18.7455V18.7194C5.43197 18.6195 5.44994 18.5203 5.48503 18.4268L8.26077 11.0248C8.38274 10.6995 8.69368 10.4841 9.04105 10.4841H20.4442C21.013 10.4841 21.4146 11.0413 21.2348 11.5809L18.7587 19.009C18.6453 19.3493 18.3269 19.5788 17.9682 19.5788H4.42144"
        :stroke="color"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
    </path>
  </g>
</template>