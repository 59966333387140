<template>
    <p>{{ name }}</p>
  
    <div class="project-list-wrapper" :class="{ 'no-projects': projectItems.length === 0 }">
      <div v-if="projectItems.length > 0" :class="{ 'project-list': true, 'single-row': isSingleRow, 'multi-row': !isSingleRow }">
        <ProjectItem
          v-for="(item, index) in sortedProjectItems"
          :key="index"
          :imgUrl="item.imgUrl"
          :imgName="item.imgName"
          :imgDate="item.date"
          :id="String(item.id)"
          :is-favorite="item.isFavorite"
        />
      </div>
  
      <div v-else class="placeholder">
        <BaseIcon :iconName="iconName" color="rgba(255,255,255, .4)"></BaseIcon>
        <p class="caption">{{ captionText }}</p>
      </div>
    </div>
  </template>
  
  <script setup>
  import { computed } from 'vue';
  import ProjectItem from '@/components/ProjectItem.vue';
  import BaseIcon from '@/components/BaseIcon.vue';
  
  const props = defineProps({
    name: String,
    projectItems: {
      type: Array,
      default: () => [],
    },
    isSingleRow: {
      type: Boolean,
      default: true,
    },
    locked: {
      type: Boolean,
      default: false,
    },
  });
  
  const sortedProjectItems = computed(() => {
    return props.projectItems.slice().sort((a, b) => new Date(b.date) - new Date(a.date));
  });
  
  const iconName = computed(() => (props.locked ? 'lock' : 'dotHorizontal'));
  
  const captionText = computed(() => {
    if (props.locked) {
      return 'It will be available in the next update';
    } else {
      return `You don't have any ${props.name.toLowerCase()} yet`;
    }
  });
  </script>
  
  <style scoped>
  .project-list {
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
    width: 100%;
    overflow-x: auto;
  }
  
  .placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #666;
    font-size: 18px;
  }
  
  .no-projects {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 20px;
    box-sizing: border-box;
    width: 100%;
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, .1);
    color: rgba(255, 255, 255, .4);
  }
  
  .caption {
    color: rgba(255, 255, 255, .4);
  }
  
  .single-row {
    flex-wrap: nowrap;
  }
  
  .multi-row {
    flex-wrap: wrap;
  }
  </style>
  