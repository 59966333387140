<script setup>
const {color} = defineProps({
  color: {
    type: String,
  },
})
</script>

<template>
  <g>
    <path
        d="M16.8002 12L7.2002 12"
        :stroke="color"
        stroke-width="2"
        stroke-linecap="round"
    >
    </path>
  </g>
</template>