<template>
    <div class="section">
        <div class="center-section">
            <div class="form-heading">
                <h1>Forgot your password?</h1>
                <p class="caption">
                    Enter your email address below and we’ll get you back on track
                </p>
            </div>
           
            <div class="signup-form">
                <form @submit.prevent="handleSubmit">
                    <base-input class="form-group" 
                                type="email" 
                                placeholder="Email" 
                                required icon="envelop"
                                v-model="email">
                    </base-input>

                    <primary-btn>Send</primary-btn>
              </form>
            </div>

            <div class="login-link caption">
                <router-link    to="/login" 
                                class="link">
                                Back to sign in
                </router-link>
            </div>
        </div>
    </div>

</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import BaseInput from '@/components/BaseInput.vue';
import PrimaryBtn from '@/components/PrimaryBtn.vue';

const email = ref('');

const router = useRouter();

async function handleSubmit() {
  try {
    const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/reset-password-email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email: email.value }),
      mode: 'no-cors'
    });

    if (response.ok) {
      router.push('/resetpass/confirm');
    } else if (response.status === 422) {
      const errorData = await response.json();
      console.error('Validation error:', errorData);
    } else {
      console.error('Error sending password reset email:', await response.text());
    }
  } catch (error) {
    console.error('Error sending password reset email:', error);
  }
}
</script>

<style scoped>
    .signup-btn-group {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 1vh;
    }

    .center-section {
        width: 35%;
        margin: auto;
    }

    .form-heading {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-bottom: 1vh;
    }

    .form-heading .caption {
        color: rgba(255, 255, 255, .4);
        width: 50%;
    }

    .form-heading img {
        max-width: 100%;
        height: auto;
        object-fit: contain;
    }

    .signup-form {
        padding: 2.5vh;
        margin-bottom: 24px;
        background-color: rgba(255, 255, 255, 0.1) !important;
        border-radius: 20px;
    }

    .reset {
        margin-top: 1vh;
        display: flex;
        justify-content: center;
    }

    .form-group {
        padding: 1vh;
    }

    .login-link {
        text-align: center;
        color: rgba(255, 255, 255, .4);
    }

    .link {
        color: white;
        text-decoration: none;
    }
</style>