<script setup>
const {color} = defineProps({
  color: {
    type: String,
  },
})
</script>

<template>
  <g>
    <path
        d="M4.80039 15.5999L9.00039 19.1999M12.0004 22.1999H21.6004M4.20039 15.5999L16.0318 3.35533C17.3057 2.08143 19.3711 2.08143 20.645 3.35533C21.9189 4.62923 21.9189 6.69463 20.645 7.96853L8.40039 19.7999L2.40039 21.5999L4.20039 15.5999Z"
        :stroke="color"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
    </path>
  </g>
</template>