<template>
    <button class="btn-primary" :disabled="disabled" @click="handleClick">
        <slot>Button</slot>
    </button>
</template>

<script setup>
    import { defineProps, defineEmits } from 'vue';

    const props = defineProps({
    disabled: {
        type: Boolean,
        default: false
    }
    });

    const emits = defineEmits(['click']);

    function handleClick(event) {
        if (props.disabled) {
            event.preventDefault();
            return;
        }
        emits('click', event);
    }
</script>

<style scoped>
.btn-primary {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: #fff;
    text-align: center;

    width: 100%;
    padding: 1vh;
    border: none;
    background: linear-gradient(99.6deg, #20AD8B 11.39%, #ACE41B 86.3%);
    border-radius: 12px;
}

.btn-primary:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(99.6deg, #20AD58 11.39%, #ACE41B 86.3%);
}

.btn-primary:active {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(99.6deg, #20AD58 11.39%, #ACE41B 86.3%);
}

.btn-primary:disabled {
    background: rgba(255, 255, 255, 0.1);
}
</style>