<script setup>
const {color} = defineProps({
  color: {
    type: String,
  },
})
</script>

<template>
  <g>
    <path
        d="M14.9435 21.176V4C14.9435 3.44771 14.4958 3 13.9435 3H9.88528C9.333 3 8.88528 3.44772 8.88528 4V21.176M14.9435 21.176L14.9419 10.8562C14.9418 10.3038 15.3896 9.856 15.9419 9.856H20C20.5523 9.856 21 10.3037 21 10.856V20.176C21 20.7283 20.5523 21.176 20 21.176H14.9435ZM14.9435 21.176H8.88528M8.88528 21.176V16.176C8.88528 15.6237 8.43757 15.176 7.88528 15.176H4C3.44771 15.176 3 15.6237 3 16.176V20.176C3 20.7283 3.44771 21.176 4 21.176H8.88528Z"
        :stroke="color"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
    </path>
  </g>
</template>