<script setup>
const {color} = defineProps({
  color: {
    type: String,
  },
})
</script>

<template>
  <g>
    <path
        d="M14.4004 12.0294C14.4004 13.3192 13.3259 14.3647 12.0004 14.3647C10.6749 14.3647 9.60039 13.3192 9.60039 12.0294C9.60039 10.7396 10.6749 9.69402 12.0004 9.69402C13.3259 9.69402 14.4004 10.7396 14.4004 12.0294Z"
        :stroke="color"
        stroke-width="2"
    >
    </path>
  </g>
</template>