<script setup>
const { color } = defineProps({
  color: {
    type: String,
    default: '#000000',
  },
});
</script>

<template>
  <g fill="none">
    <path
      d="M3 6h18v2H3zM3 11h18v2H3zM3 16h18v2H3z"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </g>
</template>
